import React from 'react';

class PC extends React.Component {

    render() {
        return(
        <div className="PC">
            <h1>PC</h1>
        </div>
        );
    }

}

export default PC;