import React from 'react';
import { Link } from 'react-router-dom';
import NavDiv from "./NavDiv";
import Menue from '../img/g956.png';
import logo from '../img/Logo.png';
import imgNews from '../img/Sym-News-Normal.png';
import imgGames from '../img/Sym-Game-Normal.png';
import imgTrivia from '../img/Sym-Trivia-Normal.png';
import imgPC from '../img/Sym-PC-Normal.png';
import imgPlaystation from '../img/Sym-Playstation-Normal.png';
import imgXbox from '../img/Sym-Xbox-Normal.png';
import imgSwitch from '../img/Sym-Switch-Normal.png';

class Navbar extends React.Component {

    render() {
        
        return (
            <div className="navbarFrame">
                <nav className="navbar" onMouseDown={console.log("Spargel")}>
                    <Link to='/'><NavDiv img={logo}/></Link>
                    <Link to='/News'><NavDiv img={imgNews}/></Link>
                    <Link to='/Spiele'><NavDiv img={imgGames} /></Link>
                    <Link to='/Trivia'><NavDiv img={imgTrivia} /></Link>
                    <Link to='/PC'><NavDiv img={imgPC} /></Link>
                    <Link to='/Playstation'><NavDiv img={imgPlaystation} /></Link>
                    <Link to='/XBox'><NavDiv img={imgXbox} /></Link>
                    <Link to='/Switch'><NavDiv img={imgSwitch} /></Link>


                   
                    




                <div className="navbarMenue"><a href=""><img src={Menue}/></a></div>
                </nav>

            </div>
        );
    }
}

export default Navbar;
