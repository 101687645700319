import React from 'react';

import Wol from '../img/Arcade.png';
import Wol2 from '../img/bioshock.jpg';
import Wol3 from '../img/Cyberpunk.png';
import MarioWorld from '../img/MarioWorld.png';
import LGSText from '../img/Titel.png';

class ScreenStart extends React.Component {
    render() {
        return (
                <div className="ScreenStart">
                    <img className="ScreenStartLGSText" src={LGSText}/>
                    <img className="ScreenStartMarioWorld" src={MarioWorld}/>    
                </div>

        );
    }

}

export default ScreenStart;

// Code

function Screen_Wechsler(){
    const Zeit = 8000; // Dauer der Anzeige Pro Bild in millisekunden
    var Status_Auswahl = 0; // mit welcher Seite wird gestartet(standard = LateGameshow Titelseite)

    const Original_Balken_Höhe = document.getElementById("Start_Screen_Auswahl_Timer").offsetHeight;
    var Balken_Höhe = Original_Balken_Höhe;
    var Counter_Zeit = Zeit/Original_Balken_Höhe;
    
    var LateGameShow_Screen_Class = document.getElementById("Start_Screen_Bild").children[0].className;
    
    
    Wolkenflug()
    Interval()


    
            // Balken Countdown

    
    

    // document.getElementById("Start_Screen_Auswahl1").addEventListener("click", ManuellerWechsel(this));
    // // document.getElementById("Start_Screen_Auswahl2").addEventListener("click", ManuellerWechsel(this));
    // // document.getElementById("Start_Screen_Auswahl3").addEventListener("click", ManuellerWechsel(this));
    // // document.getElementById("Start_Screen_Auswahl4").addEventListener("click", ManuellerWechsel(this)); 

    // function ManuellerWechsel(Objekt){
    //     var Inhalt = Objekt.innerHTML;
    //     var Screen = document.getElementById("Start_Screen_Bild").innerHTML;
    //     document.getElementById("Start_Screen_Bild").innerHTML = Inhalt;
    //     Objekt.innerHTML = Screen;
    //     clearInterval(Timer_Interval);
    // }

function Interval(){
    var Timer_Interval = setInterval(Countdown, Counter_Zeit);//Ende

    function Countdown(){            
                document.getElementById("Start_Screen_Auswahl_Timer").style.height = Balken_Höhe;
                Balken_Höhe--;

                if(Balken_Höhe == 0){
                    Balken_Höhe = Original_Balken_Höhe;
                    document.getElementById("Start_Screen_Auswahl_Timer").style.height = Original_Balken_Höhe;
                    clearInterval(Timer_Interval); 
                    Wechsler()                       
                }            
    }
}
            //Wechsel des Screens
    function Wechsler(){        
            if(document.getElementsByClassName("Start_Screen_Auswahl_Bilder")[Status_Auswahl].children[0].className == LateGameShow_Screen_Class){
                            Wolkenflug()

                        }
                        else{
                            Wolkenflug_Stopp();

                        }
            var Auswahl = document.getElementsByClassName("Start_Screen_Auswahl_Bilder")[Status_Auswahl].innerHTML;        
            var Screen = document.getElementById("Start_Screen_Bild").innerHTML; 
            
            

            document.getElementById("Start_Screen_Bild").innerHTML = Auswahl;
            document.getElementsByClassName("Start_Screen_Auswahl_Bilder")[Status_Auswahl].innerHTML = Screen;
            
            if(Status_Auswahl < document.getElementsByClassName("Start_Screen_Auswahl_Bilder").length - 1){
                Status_Auswahl++;
            }
            else{
                Status_Auswahl = 0;
            }
            Interval();
    }
    
}


//--------------------------------------Auswahl -------------------


/*
var Zeit = 8000;
var Status_Auswahl = 0;
setInterval(function(){
    if(Bild == WolkenScreen){
        wolkenflug();
        console.log("ja");
    }
    var Bild = document.getElementsByClassName("Start_Screen_Auswahl_Bilder")[Status_Auswahl].innerHTML;
    
    var Screen = document.getElementById("Start_Screen_Bild").innerHTML;

    document.getElementById("Start_Screen_Bild").innerHTML = Bild;

    document.getElementsByClassName("Start_Screen_Auswahl_Bilder")[Status_Auswahl].innerHTML = Screen;

    if(Status_Auswahl < document.getElementsByClassName("Start_Screen_Auswahl_Bilder").length - 1){
        Status_Auswahl++;
    }
    else{
        Status_Auswahl = 0;
    }
    
    
    
    if(document.getElementById("Start_Screen_Bild").children[0].className == "Start_Screen_Bild"){
        document.getElementById("EineWolke").style.display = "block";
        document.getElementById("EineWolke2").style.display = "block";
        document.getElementById("EineWolke3").style.display = "block";
        document.getElementById("EineWolke4").style.display = "block";
        document.getElementById("ZweiWolke").style.display = "block";
        document.getElementById("ZweiWolke2").style.display = "block";
        document.getElementById("DreiWolke").style.display = "block";
        document.getElementById("DreiWolke2").style.display = "block";    
        document.getElementById("Start_Screen_LateGameShow_Titel").style.width = "100%";
        document.getElementById("Start_Screen_LateGameShow_Titel").style.top = "35%";
    }
    else{
        document.getElementById("EineWolke").style.display = "none";
        document.getElementById("EineWolke2").style.display = "none";
        document.getElementById("EineWolke3").style.display = "none";
        document.getElementById("EineWolke4").style.display = "none";
        document.getElementById("ZweiWolke").style.display = "none";
        document.getElementById("ZweiWolke2").style.display = "none";
        document.getElementById("DreiWolke").style.display = "none";
        document.getElementById("DreiWolke2").style.display = "none";       
        document.getElementById("Start_Screen_LateGameShow_Titel").style.width = "80%";
        document.getElementById("Start_Screen_LateGameShow_Titel").style.top = "50%";    
    }

    Timer_Balken();
    
    wolkenflug();
}, Zeit);

function Timer_Balken(){
        var Original_Balken_Höhe = document.getElementById("Start_Screen_Auswahl_Timer").offsetHeight;
        var Balken_Höhe = Original_Balken_Höhe; 


        var Timer_Interval = setInterval(function(){
            
            document.getElementById("Start_Screen_Auswahl_Timer").style.height = Balken_Höhe;
            Balken_Höhe--;

            if(Balken_Höhe == 0){
                Balken_Höhe = Original_Balken_Höhe;
                document.getElementById("Start_Screen_Auswahl_Timer").style.height = Original_Balken_Höhe;
                clearInterval(Timer_Interval);                        
            }

        }, Zeit/Original_Balken_Höhe)
} */








 //--------------------------------------Late Game Show Cover - Wolken ------------------------------------------
 function Wolke(WolkenID){
                document.getElementById(WolkenID).style.display = "block";

                var Dauer = setInterval(Move, 1);
                var Speed = (Math.floor(Math.random() * 10) + 12) / 200 ;
                var Höhe = Math.floor(Math.random() * (document.getElementById("Start_Screen_LateGameShow_Himmel").clientHeight - 100))+ 50;
                var Wolke = document.getElementById(WolkenID);
                Wolke.style.top = Höhe;
                var Strecke = Math.floor(Math.random() * document.getElementById("Start_Screen_LateGameShow_Himmel").clientWidth + document.getElementById("Start_Screen_Auswahl").clientWidth );
                Wolke.style.left = Strecke;

                function Move() {
                    Strecke += Speed;
                    if(Strecke > document.getElementById("Start_Screen_LateGameShow_Himmel").clientWidth +document.getElementById("Start_Screen_Auswahl").clientWidth){
                        Strecke = 0;
                        Wolke.style.top = Math.floor(Math.random() * (document.getElementById("Start_Screen_LateGameShow_Himmel").clientHeight - 50))+ 50;
                        Speed = (Math.floor(Math.random() * 10) + 12) / 200 ;
                    }
                    else{
                        Wolke.style.left = Strecke;
                    }
                }
            }
        function Wolkenflug(){
            Wolke("EineWolke");
            Wolke("EineWolke2");
            Wolke("EineWolke3");
            Wolke("EineWolke4");
            Wolke("ZweiWolke");
            Wolke("ZweiWolke2");
            Wolke("DreiWolke");
            Wolke("DreiWolke2");
            document.getElementById("Start_Screen_LateGameShow_Titel").style.width = "100%";
            document.getElementById("Start_Screen_LateGameShow_Titel").style.top = "35%";
            }
            
            //--------------------------------------Late Game Show Cover - Wolken Ende --------------------------------------
        function Wolkenflug_Stopp(){                       
            document.getElementById("EineWolke").style.display = "none";
            document.getElementById("EineWolke2").style.display = "none";
            document.getElementById("EineWolke3").style.display = "none";
            document.getElementById("EineWolke4").style.display = "none";
            document.getElementById("ZweiWolke").style.display = "none";
            document.getElementById("ZweiWolke2").style.display = "none";
            document.getElementById("DreiWolke").style.display = "none";
            document.getElementById("DreiWolke2").style.display = "none";       
            document.getElementById("Start_Screen_LateGameShow_Titel").style.width = "80%";
            document.getElementById("Start_Screen_LateGameShow_Titel").style.top = "50%";    
        }





            
