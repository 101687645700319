import React from 'react';

import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import './App.scss';

// Seiten Componenten
import Home from './pages/Home';
import News from './pages/News';
import Spiele from './pages/Spiele';
import Trivia from './pages/Trivia';
import PC from './pages/Pc';
import Playstation from './pages/Playstation';
import XBox from './pages/Xbox';
import NiSwitch from './pages/Switch';
import LateGameShow from './pages/LateGameShow';


// Artikel
import ArtikelDemo from './pages/Artikel/Demo';



// Spiele
import SpieleMarioKart8 from './pages/Spiele/MarioKart8';
import CounterStrikeGlobalOffensive from './pages/Spiele/CounterStrikeGlobalOffensive';


import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 



function App() {
  return (
    <Router>
      <Navbar/>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/News" component={News}/>
          <Route path="/Spiele" exact component={Spiele}/>
          <Route path="/Trivia" component={Trivia}/>
          <Route path="/PC" component={PC}/>
          <Route path="/Playstation" component={Playstation}/>
          <Route path="/XBox" component={XBox}/>
          <Route path="/Switch" component={NiSwitch}/>
          <Route path="/LateGameShow" component={LateGameShow}/>
          <Route path="/ArtikelDemo" component={ArtikelDemo}/>
       

          {/* Spiele */}
            <Route path="/Spiele/MarioKart8" component={SpieleMarioKart8}/>
            <Route path="/Spiele/CounterStrikeGlobalOffensive" component={CounterStrikeGlobalOffensive}/>

        </Switch>
      <Footer/>
    </Router>
    
              
  );
}

export default App;
