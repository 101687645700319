import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <div className="footerFrame Shadow">
                <div className="footerleft">
                    <h2>Über Uns:</h2>
                    <Link to='/LateGameShow'><p>Late Game Show</p></Link>   
                </div>
            </div>

            );
        }
    }
    
export default Footer;