import React from 'react';

import imgInstagram from '../img/Instagram.png';
import imgTwitter from '../img/Twitter.png';
import imgYoutube from '../img/Youtube.png';
import imgPinterest from '../img/Pinterest.png';

class SocialMedia extends React.Component {
    render() {
        return (
                <div className="socialMediaFrame Shadow" >
                        <div className="socialMediaList imgCenter">
                            <a href="https://www.instagram.com/lategameshowde"><div className="socialMediaListObject"><img src={imgInstagram} /></div></a>
                            <a href="https://www.youtube.com/channel/UCVG4wtJD1D1gEd39MCrWf1Q"><div className="socialMediaListObject"><img src={imgYoutube} /></div></a>
                            <a href="https://twitter.com/LateGameShowDE"><div className="socialMediaListObject"><img src={imgTwitter} /></div></a>
                            <a href="https://www.pinterest.de/LateGameShow/"><div className="socialMediaListObject"><img src={imgPinterest} /></div></a>
                        </div>       
                
                </div>

        );
    }
}

export default SocialMedia;
