import React from 'react';
import { Link } from 'react-router-dom';


class Component extends React.Component {
    render() {
        console.log("Component " + this.props.data.classes);
        return (
            
            
                <div key="" id={this.props.data.id}  className={"Component " + this.props.data.classes}>
                    <Link to={this.props.data.link}>
                    
                    <img alt={this.props.data.imgalt} src= {this.props.data.img}/>
                    <div>
                        <h1>{this.props.data.title}</h1>
                        <h2>{this.props.data.heading}</h2>
                        <p>{this.props.data.text}</p>
                        <div>{this.props.data.tag}</div>
                    
                    </div>
                   </Link> 
                </div>
            
        );
    }
}

export default Component;
