import React from 'react';

class Playstation extends React.Component {

    render() {
        return(
        <div className="Playstation">
            <h1>Playstation</h1>
        </div>
        );
    }

}

export default Playstation;