import React from 'react';

import Component from "../components/Component";
import Screen from "../components/Screen";
import SocialMedia from "../components/SocialMedia";





import Grid01 from '../img/Cuphead.jpg';
import Img from "../img/img";

class Home extends React.Component {
    
    render() {
        // Layout (Grid Style)
        const AppGrid = {gridTemplateRows: "600px auto 800px 300px 600px 600px 800px"};
        const AppGrid01 = {gridColumn: "1 / 11",gridRow: "1",zIndex: "0"};//Screen
        const AppGrid02 = {gridColumn: "11 / 13",gridRow: "1",zIndex: "1"};//SocialMedia
        const AppGrid03 = {gridColumn: "1 / 13",gridRow: "2",zIndex: "3"};//BoxList
        const AppGrid04 = {gridColumn: "1 / 5",gridRow: "3",zIndex: "1"};//ListeDetail
        const AppGrid05 = {gridColumn: "5 / 13",gridRow: "3",zIndex: "2"};//Grid
        const AppGrid06 = {gridColumn: "1 / 13",gridRow: "4",zIndex: "0"};//Banner
        const AppGrid07 = {gridColumn: "1 / 13",gridRow: "5",zIndex: "1"};//KreisListe
        const AppGrid08 = {gridColumn: "1 / 13",gridRow: "6",zIndex: "0"};//BannerList
        const AppGrid09 = {gridColumn: "1 / 13",gridRow: "7",zIndex: "1"};//Grid
        // Layout (Grid Style) --- ENDE

        const BoxListSpiele = [
            {classes:"BoxV2", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"BoxV2", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"BoxV2", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymMinecraft, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymWitcher3, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymZeldaBreathoftheWild, link:"/Spiele/CSGO"},
        ];
        const DetailListNews = [
            {classes:"RechteckV1", img:Img.SymCuphead, link:"/Spiele/CSGO", title:"Test", heading:"test", tag:"oron"}, 
            {classes:"RechteckV1", img:Img.SymCuphead, link:"/Spiele/CSGO", title:"Test", heading:"test", tag:"oron"}, 
            {classes:"RechteckV1", img:Img.SymCuphead, link:"/Spiele/CSGO", title:"Test", heading:"test", tag:"oron"}, 
        ];
        const Kategorien = [
            {classes:"BoxV3", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"BoxV3", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"BoxV3", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"BoxV3", img:Img.SymMinecraft, link:"/Spiele/CSGO"},
            {classes:"BoxV3", img:Img.SymWitcher3, link:"/Spiele/CSGO"},
            {classes:"BoxV3", img:Img.SymZeldaBreathoftheWild, link:"/Spiele/CSGO"},
        ];
        const TestC = [
            {classes:"BannerListe", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"BannerListe", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"BannerListe", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"BannerListe", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"BannerListe", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
        ];
        const Grid = [
            {classes:"Grid01", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"Grid02", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"Grid03", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"Grid04", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"Grid05", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"Grid06", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"Grid07", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"Grid08", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"Grid09", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"Grid10", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
        ];
      
        

        return(
        <div className="AppGrid" style={AppGrid}>
            <div className="AppGrid01" style={AppGrid01}><Screen /></div>
            <div className="AppGrid02" style={AppGrid02}><SocialMedia /></div>
            <div className="AppGrid03 Shadow Ho" style={AppGrid03}>
            
                {BoxListSpiele.map((value) => {
                    return <Component data = {value}/>
                })}
                
            </div>           
            <div className="AppGrid04 Shadow" style={AppGrid04}>
                {DetailListNews.map((value) => {
                    return <Component data = {value}/>
                })}
            </div>
            <div className="AppGrid05" style={AppGrid05}>
                <div className="Grid imgCenter Shadow">
            {Grid.map((value) => {
                    return <Component data = {value}/>
                })}</div></div>            
            <div className="AppGrid06 imgCenter" style={AppGrid06}><img src={Grid01}/></div>
            <div className="AppGrid07" style={AppGrid07}>
                {Kategorien.map((value) => {
                    return <Component data = {value}/>
                })}
            </div>
            <div className="AppGrid08" style={AppGrid08}>  
            {TestC.map((value) => {
                    return <Component data = {value}/>
                })}
            </div>
            <div className="AppGrid09" style={AppGrid09}>
            <div className="Grid imgCenter Shadow">
                
                
            {Grid.map((value) => {
                    return <Component data = {value}/>
                })}
            </div>
            </div>
                
        </div>
        );
    }

}

export default Home;