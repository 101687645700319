import React from 'react';
import PostBlock from '../../components/PostBlock';

import Bild1 from '../../img/Arcade.png';


class Demo extends React.Component {

    render() {
        return(
            <div>
                <PostBlock 
                    img={Bild1} 
                    title="Arcade Apple"
                    text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                />    

            </div>
            );
        }
    
    }
    
    export default Demo;            