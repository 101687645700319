import React from 'react';

class NiSwitch extends React.Component {

    render() {
        return(
        <div className="Switch">
            <h1>Switch</h1>
        </div>
        );
    }

}

export default NiSwitch;