import React from 'react';

class XBox extends React.Component {

    render() {
        return(
        <div className="XBox">
            <h1>XBox</h1>
        </div>
        );
    }

}

export default XBox;