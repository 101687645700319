import React from 'react';
import { Link } from 'react-router-dom';


class PostBlock extends React.Component {

    render() {
        
        return (
            <div className="PostBlock">
                <div className="PostBlockText">
                    <h1>{this.props.title}</h1>
                    <p>{this.props.text}</p>    
                </div>
                
                <img src={this.props.img}></img>

    

            </div>
        );
    }
}

export default PostBlock;
