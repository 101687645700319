// Alle Bilder aus dem img Ordner importieren
import Arcade from "./Arcade.png";
import bioshock from "./bioshock.jpg";
import Cuphead from "./Cuphead.jpg";
import Cyberpunk from "./Cyberpunk.png";
//Spiele Symbole
import SymCuphead from "./Spiele/SymCuphead.png";
import SymDota2 from "./Spiele/SymDota2.png";
import SymLOL from "./Spiele/SymLOL.png";
import SymMarioKart8 from "./Spiele/SymMarioKart8.png";
import SymMarioMaker2 from "./Spiele/SymMarioMaker2.png";
import SymMinecraft from "./Spiele/SymMinecraft.png";
import SymPUBG from "./Spiele/SymPUBG.png";
import SymRainbowsixsiege from "./Spiele/SymRainbowsixsiege.png";
import SymSuperSmashBros from "./Spiele/SymSuperSmashBros.png";
import SymWitcher3 from "./Spiele/SymWitcher3.png";
import SymZeldaBreathoftheWild from "./Spiele/SymZeldaBreathoftheWild.png";
 
export default 
{
    Arcade: Arcade, 
    bioshock: bioshock,
    Cuphead: Cuphead,
    Cyberpunk: Cyberpunk,
    SymCuphead: SymCuphead,
    SymDota2: SymDota2,
    SymLOL: SymLOL,
    SymMarioKart8: SymMarioKart8,
    SymMarioMaker2: SymMarioMaker2,
    SymMinecraft: SymMinecraft,
    SymPUBG: SymPUBG,
    SymRainbowsixsiege: SymRainbowsixsiege,
    SymSuperSmashBros: SymSuperSmashBros,
    SymWitcher3: SymWitcher3,
    SymZeldaBreathoftheWild: SymZeldaBreathoftheWild,

}
