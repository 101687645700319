import React from 'react';
import ScreenStart from "./ScreenStart";
import { Link } from 'react-router-dom';


import Bild1 from '../img/Arcade.png';
import Bild2 from '../img/bioshock.jpg';
import Bild3 from '../img/Cyberpunk.png';
import Bild4 from '../img/E32019.jpg';
import LGSText from '../img/Titel.png';

class Screen extends React.Component {
    render() {
        return (
                <div id="screenFrame">
                    <div className="screen ">
                        <div className="screenChoose imgCenter">
                            <Link to='/ArtikelDemo'><div className="screenChooseObject"><img src={Bild1} /></div></Link>
                            <Link to='/ArtikelDemo'><div className="screenChooseObject"><img src={Bild2} /></div></Link>
                            <Link to='/ArtikelDemo'><div className="screenChooseObject"><img src={Bild3} /></div></Link>
                            <Link to='/ArtikelDemo'><div className="screenChooseObject"><img src={Bild4} /></div></Link>
                        </div>       
                        <div className="screenImg">
                            <ScreenStart/>
                        </div>
                    </div>
                </div>

        );
    }
}

export default Screen;
