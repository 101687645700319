import React from 'react';
import Screen from "../components/Screen"

class News extends React.Component {

    render() {
        return(
        <div className="News">
            <Screen/>
        </div>
        );
    }

}

export default News;