import React from 'react';
import Component from '../components/Component';

import Img from "../img/img";

class Spiele extends React.Component {

    render() {
        const BoxListSpiele = [
            {classes:"BoxV2", img:Img.SymCuphead, link:"/Spiele/CSGO"}, 
            {classes:"BoxV2", img:Img.SymDota2, link:"/Spiele/CSGO"}, 
            {classes:"BoxV2", img:Img.SymMarioKart8, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymMinecraft, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymWitcher3, link:"/Spiele/CSGO"},
            {classes:"BoxV2", img:Img.SymZeldaBreathoftheWild, link:"/Spiele/CSGO"},
        ];

        return(
        <div className="Spiele">
                {BoxListSpiele.map((value) => {
                    return <Component data = {value}/>
                })}
        </div>
        );
    }

}

export default Spiele;