import React from 'react';

class Trivia extends React.Component {

    render() {
        return(
        <div className="Trivia">
            <h1>Trivia</h1>
        </div>
        );
    }

}

export default Trivia;