import React from 'react';

class NavDiv extends React.Component {
    render() {
        return (
        
            <div className="navbarDiv"><div href={this.props.link}><img  onMouseEnter={() => this.styl} src={this.props.img}/></div></div>
            
        );
    }
}

export default NavDiv;
